.logo{
    margin-left: 2.5%;
}
.link{
    margin-left: auto;
}
.link a{
    width: 10rem;
    margin-right: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 115.3%;
    text-align: justify;
    color: #383838;
}
@media(max-width: 600px){
    .link a{
        width: 12rem;
    }
}

.link a:hover{
    color:#06608A !important;
}
/*
@media(max-width: 991px){
    .link a{
        margin-left: 7%;
    }
}

@media(max-width: 1200px){
    .link{
        margin-left: 22%;
    }
}
@media(max-width: 1100px){
    .link{
        margin-left: 16%;
    }
}

@media(min-width: 992px) and (max-width: 1050px) {
    .link{
        margin-left: 15%;
    }
}
*/
.border{
    box-shadow: transparent;
}